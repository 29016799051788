import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import enigma from "../Assets/enigma.png";

export default function Navbar(props) {
  const [show, setShow] = useState(false);

  function toggle() {
    setShow((prev) => !prev);
  }

  React.useEffect(
    function () {
      const x = document.querySelector(".sidebar");
      show ? (x.style.width = "295px") : (x.style.width = 0);
    },
    [show],
  );
  return (
    <>
      <nav className="navbar desktop" onMouseEnter={toggle}>
        <Link to="/">
          <div className="name">
            <h1 className="desktop">
              DUSHYÂNTH <span>G</span>
              <span className="last-name">ALI</span>
            </h1>
          </div>
          <img className="fox" src={enigma} alt="fox"></img>
        </Link>
      </nav>
      <div className="sidebar" onMouseLeave={toggle}>
        <div className="side-list">
          <NavLink to="/">
            <h1>
              <span className="list-letter">H</span>OME
            </h1>
          </NavLink>
          {/* <NavLink to="/about">
            <h1>
              <span className="list-letter">A</span>BOUT
            </h1>
            <hr></hr>
          </NavLink> */}
          <NavLink to="/projects">
            <h1>
              <span className="list-letter">T</span>INKER
            </h1>
            <hr></hr>
          </NavLink>
          <a href="https://blog.dushyanth.in/" target="_blank">
            <h1>
              <span className="list-letter">B</span>LOG
            </h1>
            <hr></hr>
          </a>
          <NavLink to="/education">
            <h1>
              <span className="list-letter">E</span>DUCATION
            </h1>
            <hr></hr>
          </NavLink>
          <NavLink to="/contact">
            <h1>
              <span className="list-letter">C</span>ONTACT
            </h1>
            <hr></hr>
          </NavLink>
        </div>
      </div>
    </>
  );
}
