import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Loading from "../Components/Loading";
import NavbarMobile from "../Components/NavbarMobile";
import Project from "../Components/project/Project";

import cfourpic1 from "../Assets/cfourmob.png";
import cfourpic2 from "../Assets/cfourdesk.png";

import imaigepic1 from "../Assets/imaigemob.png";
import imaigepic2 from "../Assets/imaigedesk.png";

import bookpic1 from "../Assets/bookmyplacemob.png";
import bookpic2 from "../Assets/bookmyplacedesk.png";

import dapppic1 from "../Assets/dappmob.png";
import dapppic2 from "../Assets/dappdesk.png";

import manapic1 from "../Assets/manamob.png";
import manapic2 from "../Assets/manadesk.png";

import twitter from "../Assets/twitter.png";
import thinnai from "../Assets/thinnai.png";
import suit from "../Assets/suit.png";

export default function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page">
      <Navbar className="desktop" />
      <NavbarMobile className="mobile" />
      <div className="content">
        <h1>TINKER LAB</h1>
        <hr></hr>
        <div className="projectslist">
          <Project
            h="EMF Landing Page"
            p="A captivating fitness studio landing page built using Three.js, React Three Fiber and Pixotronics."
            pic3={suit}
            yt="https://www.youtube.com/embed/pomNA8XIrjo"
            link="https://dushyanth-neomind.netlify.app/"
            class="suit"
          />
          <Project
            h="shopify x Thirdweb"
            p="Experience Token Powered Commerce like never before; Using Blockchain components."
            pic1={twitter}
            yt="https://www.youtube.com/embed/6FwCl-y7vSc"
            link="https://twitter.com/iam_dushu/status/1642839616645636097"
            class="twt"
          />
          <Project
            h="Thinnai"
            p="Thinnai allows you to book a unique semi-private ambience to dine, work remotely, have a personal or professional meeting."
            pic1={thinnai}
            yt="https://www.youtube.com/embed/JL2dw3i_3zw"
            link="https://thinnai.vercel.app"
            class="twt thin"
          />
          <Project
            h="cFour Game"
            p="Connect Four is a two-player strategy game. Built using JavaScript, React.js, Context API and Sass. Give it a try!"
            pic1={cfourpic1}
            pic2={cfourpic2}
            link="https://cfour.netlify.app/"
          />
          <Project
            h="ImAIge"
            p="Create images built by Artificial Intelligence (AI), Built using MERN stack & DALL-E's API; An AI tool by OpenAI."
            pic1={imaigepic1}
            pic2={imaigepic2}
            link="https://imaige.netlify.app/"
          />
          <Project
            h="bookMyPlace"
            p="A Full stack app built on MERN stack with User Authentication using JSON Web Tokens, bcrypt.js & State Management on Context API."
            pic1={bookpic1}
            pic2={bookpic2}
            link="https://bookmyplace.netlify.app/"
          />
          <Project
            h="My First Dapp"
            p="A web3 Smart contract app written in solidity, React and Javascript."
            pic1={dapppic1}
            pic2={dapppic2}
            link="https://myfirstdapp.netlify.app/"
          />
          <Project
            h="Mana Sampadha"
            p="A Company Portfolio built with React & Framer motion animations for a client as a Freelance Dev."
            pic1={manapic1}
            pic2={manapic2}
            link="https://manasampadha.netlify.app/"
            mvp="https://manasampada.in/"
            mvp2="https://manasampadha.netlify.app/"
          />
        </div>
      </div>
    </div>
  );
}
